import React from "react"

import MainLayout from "../components/MainLayout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <MainLayout>
    <Seo title="404: Not found" />
  </MainLayout>
)

export default NotFoundPage
